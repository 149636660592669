import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'
import type { DefaultTFuncReturn } from 'i18next'
import type { FC } from 'react'
import { Fragment } from 'react'

import { cn } from '~/utils/cn'

interface Props extends VariantProps<typeof titleStyles> {
  as?: 'h1' | 'h2'
  title: string
  description?: string | DefaultTFuncReturn
  className?: string
  blurIdx?: number[]
  splitWords?: boolean
}

const titleStyles = cva(['relative w-fit font-medium text-foreground'], {
  variants: {
    size: {
      md: 'text-[32px] leading-[1.1] lg:text-[56px]',
      lg: 'text-[36px] leading-[1.06] lg:text-[80px]',
    },
  },
  defaultVariants: { size: 'md' },
})

export const SectionTitle: FC<Props> = ({
  as: Element = 'h2',
  title,
  description,
  className,
  size,
  blurIdx,
  splitWords,
}) => {
  return (
    <div id="wrapper" className={cn('text-center', className)}>
      <Element id="title" className={cn(titleStyles({ size }))}>
        {splitWords
          ? title.split(' ').map((word, i) => (
              <Fragment key={word + i}>
                {word == '/n' ? (
                  <br />
                ) : (
                  <span
                    id="word"
                    className={cn(
                      'inline-block',
                      i !== title.split(' ').length - 1 &&
                        'whitespace-pre-wrap',
                      blurIdx?.includes(i) &&
                        'relative isolate before:absolute before:-bottom-1 before:-left-8 before:-right-8 before:top-2 before:-z-10 before:animate-blur-breathe before:rounded-[100%] before:bg-gradient-to-tr before:from-landing-components-gradient-from before:via-landing-components-gradient-via before:to-landing-components-gradient-to before:blur-xl '
                    )}
                  >
                    {`${word} `}
                  </span>
                )}
              </Fragment>
            ))
          : title}
      </Element>
      {description && (
        <p
          id="description"
          className="mt-1.5 text-sm text-foreground/50 lg:mt-4 lg:text-md "
        >
          {splitWords
            ? description.split(' ').map((word, i) => (
                <Fragment key={word + i}>
                  {word == '/n' ? (
                    <br />
                  ) : (
                    <span
                      id="description-word"
                      className={cn(
                        'inline-block',
                        i !== description.split(' ').length - 1 &&
                          'whitespace-pre-wrap'
                      )}
                    >
                      {`${word} `}
                    </span>
                  )}
                </Fragment>
              ))
            : description}
        </p>
      )}
    </div>
  )
}
