import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useTranslation } from 'react-i18next'

import { Form } from '~/helpers/form'
import { joinWaitlistSchema } from '~/schemas/contact'
import { cn } from '~/utils/cn'

import { ImageOrbit } from './ImageOrbit'
import { SectionTitle } from './SectionTitle'
import { Button } from './UI/Button'
import { Alert } from '../ui/Alert'
import { InputGroup } from '../ui/Inputs'

gsap.registerPlugin(ScrollTrigger)

export const WaitlistForm = ({ className }: { className?: string }) => {
  const { t, i18n } = useTranslation()

  return (
    <Form
      id="waitlistForm"
      schema={joinWaitlistSchema}
      mode="onBlur"
      className={cn(className)}
      action="/contact-us"
      hiddenFields={['reason']}
      values={{
        reason: 'joinWaitlist',
      }}
    >
      {({ Field, register, getFieldState, formState, submit }) => {
        const fieldState = getFieldState('email')
        return (
          <>
            <Field name="reason" />
            <Field name="email" className="flex flex-col items-stretch gap-2">
              {({ Label }) => {
                return (
                  <InputGroup>
                    <InputGroup.Input
                      id="email"
                      type="email"
                      size="lg"
                      placeholder={t('page.landing.waitlist.placeholder')!}
                      className="transform-gpu rounded-[16px] border-foreground/2 bg-foreground/4  backdrop-blur-lg"
                      {...register('email')}
                    />
                    <InputGroup.Addon className="!pr-1">
                      {/* Desktop */}
                      <Button
                        variant="gradient"
                        className="hidden lg:block"
                        onClick={submit}
                      >
                        {t('page.landing.waitlist.button_label')}
                      </Button>
                      {/* Mobile */}
                      <Button
                        variant="gradient"
                        className="block lg:hidden"
                        onClick={submit}
                      >
                        {t('page.landing.waitlist.button_label_mobile')}
                      </Button>
                    </InputGroup.Addon>
                  </InputGroup>
                )
              }}
            </Field>

            {formState.errors?.root?.message && fieldState.isDirty && (
              <div className="flex-1 xs:flex-none">
                <Alert
                  className="mt-3"
                  type="error"
                  title={t(formState.errors?.root?.message)!}
                />
              </div>
            )}

            {fieldState.error?.message && fieldState.isDirty && (
              <div className="flex-1 xs:flex-none">
                <Alert
                  className="mt-3"
                  type="error"
                  title={t(fieldState.error?.message)!}
                  description={
                    i18n.exists(`${fieldState.error?.message}_description`)
                      ? t(`${fieldState.error?.message}_description`)
                      : ''
                  }
                />
              </div>
            )}
          </>
        )
      }}
    </Form>
  )
}

export const Waitlist = ({ className }: { className?: string }) => {
  // Hooks
  const { t } = useTranslation()

  return (
    <div className={cn(className)}>
      <div
        className={cn(
          'fixed inset-0 z-30 grid h-[100vh] w-full place-items-center overflow-hidden'
        )}
      >
        <ImageOrbit className="absolute" />

        <div className="flex w-full flex-col items-center">
          <div
            id="tag"
            className="mx-auto mb-4 w-fit rounded-sm border border-foreground/8 bg-foreground/4 px-3 py-2 text-xs text-foreground/50"
          >
            {t('page.landing.waitlist.tag')}
          </div>
          <SectionTitle
            as="h2"
            title={t('page.landing.waitlist.title')}
            size="md"
            splitWords
            className=""
          />
          <WaitlistForm className="relative z-10 mt-6 w-full max-w-[361px] lg:max-w-[438px]" />
        </div>
      </div>
    </div>
  )
}
